const normalType = ['transBaseMoney', 'transBonusMoney', 'transPresentMoney']

export const fundTypes = {
    IMPREST: {text: '充值', label: '', props: ['transBaseMoney']},
    DRAWING: {text: '提款',  label: '', props: ['transBonusMoney']},
    AGENCYBUY: {text: '普通', label: '', props: ['transBaseMoney', 'transBonusMoney', 'transPresentMoney']},
    AUTOBUY: {text: '自动多期投注', label: '', props: ['transBaseMoney', 'transBonusMoney', 'transPresentMoney']},
    AUTOBUY306: {text: '多期投注扣款', label: '', props: ['transBaseMoney', 'transBonusMoney', 'transPresentMoney']},
    DISPATCH: {text: '返奖', label: '', props: ['transBonusMoney']},
    RETREAT: {text: '退款', label: '', props: ['transBaseMoney', 'transBonusMoney', 'transPresentMoney']},
    PRESENT: {text: '赠金', label: '', props: ['transPresentMoney']},
    BETCARDTRANS:{text: '投注卡转账', label: '', props: ['transBaseMoney', 'ransBonusMoney', 'transPresentMoney']},
    CORRACC:{text: '平账', label: '', props: ['transBaseMoney', 'transBonusMoney', 'transPresentMoney']},
    DEDUCT:{text: '扣款', label: '', props: ['transBaseMoney', 'transBonusMoney', 'transPresentMoney']},
    FOLLOWBUY:{text: '追号', label: '', props: normalType},
    GIFTCARD:{text: '序列号充值', label: '', props: normalType},
    LOCALTRANSFER:{text: '本账户资金互转', label: '', props: normalType},
    MANUACHARG:{text: '人工冲正', label: '', props: normalType},
    OVERDUE:{text: '扣除过期赠金', label: '', props: normalType},
    PLANENSURE:{text: '合买方案保底', label: '', props: normalType},
    POINTEXCHANGE:{text: '积分兑换赠金', label: '', props: normalType},
    DRAWFAILURE:{text: '提现失败退款', label: '', props: normalType},
    SUBSCRIBE:{text: '合买认购', label: '', props: normalType},
    RECOVERY:{text: '回收', label: '', props: normalType},
    REDCOUPON:{text: '赠送红包', label: '', props: normalType},
    MANUAREPARATIONS:{text: '人工赔款', label: '', props: normalType},
    SENDLOTTERY:{text: '送彩票用户资金', label: '', props: normalType},
    SENDLOTTERYDEDUCT:{text: '领彩票扣款', label: '', props: normalType},
    SENDLOTTERYREFUND:{text: '送彩票退款', label: '', props: normalType},
    OTHER:{text: '其他', label: '', props: normalType},
}