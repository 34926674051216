<template>
    <div class='no_data'>
        <img src="../../assets/imgs/no-data.png" alt="">
        <p>{{tip}}</p>
    </div>
</template>
<script lang='ts'>
export default {
    name: '',
    props: {
        tip : {
            type: String,
            default: '暂无数据...'
        }
    }
}
</script>
<style lang='scss' scoped>
.no_data {
    padding-top: 10px;
    text-align: center;
    padding-top: 65px;
    img {
        width: 150px;
        height: auto;
    }
    p {
        // font-size: $font-big;
        color: $gray-dark;
        padding-top: 5px;
    }
}
</style>