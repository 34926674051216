import ssqIcon from '../assets/imgs/home_icon_ssq.png'
import sixplusone from '../assets/imgs/home_icon_6and1.png'
import fifteen from '../assets/imgs/home_icon_15pick5.png'
import cx4Icon from '../assets/imgs/home_icon_cx4.png'
import fc3d from '../assets/imgs/home_icon_fc3d.png'
import seven from '../assets/imgs/home_icon_7lc.png'
// import kl8 from '../assets/imgs/home_icon_kl8.png'

export const lotterys = [
    {url: ssqIcon, name: '双色球', tip: '2元可中1000万', hot: true, flag: '今日开奖', id: 11},
    {url: fc3d, name: '福彩3D', tip: '固定奖，天天开', hot: true, flag: '', id: 51},
    {url: seven, name: '七乐彩', tip: '单注最高500万', hot: false, flag: '', id: 24},
    {url: sixplusone, name: '东方6+1', tip: '生肖+数字=大奖', hot: false, flag: '', id: 61},
    {url: cx4Icon, name: '天天彩选4', tip: '简单四位赢万元', hot: false, flag: '', id: 52},
    {url: fifteen, name: '15选5', tip: '浮动奖金,天天开', hot: false, flag: '', id: 23},
    // {url: kl8, name: '快乐8', tip: '单注可中500万', hot: false, flag: ''},
]

// 通过ID获取彩种信息
export const gameIDMap = new Map()
lotterys.forEach(ele => {
    if (ele.id) {
        gameIDMap.set(ele.id, {id: ele.id, name: ele.name, icon: ele.url})
    }
})

// 通过彩种名获取彩种信息
export const lotteryNameMap = new Map()
lotterys.forEach(ele => {
    if (ele.name) {
        lotteryNameMap.set(ele.name, {id: ele.id, name: ele.name, icon: ele.url})
    }
})

export const ssqGameId = lotteryNameMap.get('双色球')&&lotteryNameMap.get('双色球').id

// single: 单式 multiply：复式 sumValue： 和值
// 双色球多期数据
const ssqInfoMachine =  [
    {
        label: '双色球机选多期',
        type: 'single',
        orderNumber: 'a33611'
    },
]
// 3D多期数据
const fc3DInfoMachine = [
    {
        label: '直选',
        type: 'single',
        orderNumber: 'a0311'
    },
    {
        label: '组选',
        type: 'single',
        orderNumber: 'a0331'
    }
]
// 彩选4
const cx4Machine = [
    {
        label: '直选',
        type: 'single',
        orderNumber: 'a04111'
    },
    {
        label: '组选',
        type: 'single',
        orderNumber: 'a04131'
    }
]
// 七乐彩多期数据
const sevenLcMachine = [
    {
        label: '七乐彩机选多期',
        type: 'single',
        orderNumber: 'a3011'
    },
]
// 15选5
const fifteenChooseFiveMachine = [
    {
        label: '15选5机选多期',
        type: 'single',
        orderNumber: 'a1511'
    },
]
// 6+1
const sixPlusOneMachine = [
    {
        label: '东方6+1机选多期',
        type: 'single',
        orderNumber: 'a0611'
    },
]
// 双色球
const ssqInfo = {
    modeIndex: 0,
    modes: [
        {
            label: '单式',
            type: 'single',
            multiPours: true,
            multiTimes: true,
            machineChoose: true,
            showBallsIndex: -1,
            orderNumber: ['a3362','a33622'],
            showBalls: {},
            genPourNumberName: 'ssqSingle',
            startNum: 1,
            autoLotteryNumber: 'a33621',
            balls: [
                {label: '请选择6个红球', type: 'single', min: 6, max: 6, numberType: true, length: 33, color: 'red'},
                {label: '请选择1个蓝球', type: 'single', min: 1, max: 1, numberType: true, length: 16, color: 'blue'}
            ]},
        {
            label: '复式',
            orderNumber: ['a3363', 'a33632'],
            type: 'multiply',
            machineChoose: false,
            betNumberArr: [],
            multiPours: false,
            multiTimes: true,
            autoLotteryNumber: 'a33631',
            chooseNum: 6, 
            startNum: 1,
            tips: '请至少选择7个红球+1个篮球或者6个红球+2个篮球',
            split:true,
            balls: [
                {label: '请至少选择6个红球',type: 'compound', min: 6, max: 12, numberType: true, length: 33, color: 'red'},
                {label: '请至少选择1个蓝球', type: 'times', prop: 'length', min: 1, max: 16, numberType: true, length: 16, color: 'blue'}
            ]
        }
    ]
}

// // 选号数据模板
// const chooseTemplate = {
//     modeIndex: 0,
//     modes: [
//         {
//             label: '',
//             type: '',
//             multiPours: false,
//             multiTimes: false,
//             machineChoose: true,
//             showBallsIndex: -1,
//             orderNumber: '',
//             startNum: 1,
//             betNumberArr: [{redBalls: [8, 11, 16, 32, 44, 48], blueBalls: [0, 1, 2, 4]}],
//             balls: [
//                 {label: '请选择6个红球', type: 'single', min: 6, max: 6, numberType: true, length: 33, color: 'red'},
//             ]
//         }
//     ]
// }
// 东方6+1
const sixPlusOne = {
    modeIndex: 0,
    modes: [
        {
            label: '单式',
            type: 'single',
            multiPours: true,
            multiTimes: true,
            machineChoose: true,
            showBallsIndex: -1,
            orderNumber: ['a062', 'a0622'],
            autoLotteryNumber: 'a0621',
            startNum: 0,
            split: true,
            tip: '基本号码至少选择6个',
            balls: [
                {label: '请选择1个基本号码', type: 'single', min: 1, max: 1, numberType: true, length: 10, color: 'red'},
                {label: '请选择1个基本号码', type: 'single', min: 1, max: 1, numberType: true, length: 10, color: 'red'},
                {label: '请选择1个基本号码', type: 'single', min: 1, max: 1, numberType: true, length: 10, color: 'red'},
                {label: '请选择1个基本号码', type: 'single', min: 1, max: 1, numberType: true, length: 10, color: 'red'},
                {label: '请选择1个基本号码', type: 'single', min: 1, max: 1, numberType: true, length: 10, color: 'red'},
                {label: '请选择1个基本号码', type: 'single', min: 1, max: 1, numberType: true, length: 10, color: 'red'},
                {label: '请选择生肖', type: 'single', min: 1, max: 1, numberType: false, length: 10, color: 'blue', labels: ['鼠','牛','虎','兔','龙','蛇','马','羊','猴','鸡','狗','猪']},
            ]
        }
    ]
}

// 天天彩选4
const cx4 = {
    modeIndex: 0,
    modes: [
        {
            label: '直选-单式',
            type: 'single',
            multiPours: true,
            multiTimes: false,
            machineChoose: true,
            showBallsIndex: -1,
            orderNumber: 'a0412',
            autoLotteryNumber: 'a04121',
            startNum: 0,
            tip: '每位至少选择一个号码',
            rule: '投注号码与开奖号码相同，且按位相符，即中10000元。如1234',
            balls: [
                {label: '千位', type: 'single', min: 1, max: 1, numberType: true, length: 10, color: 'red'},
                {label: '百位', type: 'single', min: 1, max: 1, numberType: true, length: 10, color: 'red'},
                {label: '十位', type: 'single', min: 1, max: 1, numberType: true, length: 10, color: 'red'},
                {label: '个位', type: 'single', min: 1, max: 1, numberType: true, length: 10, color: 'red'},
            ]
        },
        {
            label: '组选4-单式',
            type: 'cx4Group',
            multiPours: true,
            multiTimes: false,
            machineChoose: true,
            showBallsIndex: -1,
            orderNumber: 'a0414',
            autoLotteryNumber: 'a04141',
            startNum: 0,
            rule: '投注号码与开奖号码相同，顺序不限，即中2500元。如3335',
            balls: [
                {label: '重号', type: 'single', min: 1, max: 1, numberType: true, length: 10, color: 'red'},
                {label: '个位', type: 'single', min: 1, max: 1, numberType: true, length: 10, color: 'red'},
            ]
        },
        {
            label: '组选6-单式',
            type: 'cx4Group',
            multiPours: true,
            multiTimes: false,
            machineChoose: true,
            showBallsIndex: -1,
            orderNumber: 'a0414',
            autoLotteryNumber: 'a04141',
            startNum: 0,
            rule: '投注号码与开奖号码相同，顺序不限，即中1600元。如2244',
            balls: [
                {label: '重号', type: 'single', min: 1, max: 1, numberType: true, length: 10, color: 'red'},
                {label: '重号', type: 'single', min: 1, max: 1, numberType: true, length: 10, color: 'red'},
            ]
        },
        {
            label: '组选12-单式',
            type: 'cx4Group',
            multiPours: true,
            multiTimes: false,
            machineChoose: true,
            showBallsIndex: -1,
            orderNumber: 'a0414',
            autoLotteryNumber: 'a04141',
            startNum: 0,
            rule: '投注号码与开奖号码相同，顺序不限，即中800元。如2245',
            balls: [
                {label: '重号', type: 'single', min: 1, max: 1, numberType: true, length: 10, color: 'red'},
                {label: '十位', type: 'single', min: 1, max: 1, numberType: true, length: 10, color: 'red'},
                {label: '个位', type: 'single', min: 1, max: 1, numberType: true, length: 10, color: 'red'},
            ]
        },
        {
            label: '组选24-单式',
            type: 'cx4Group',
            multiPours: true,
            multiTimes: false,
            machineChoose: true,
            showBallsIndex: -1,
            orderNumber: 'a0414',
            autoLotteryNumber: 'a04141',
            startNum: 0,
            rule: '投注号码与开奖号码相同，顺序不限，即中400元。如2468',
            balls: [
                {label: '请选择4个号码', type: 'single', min: 4, max: 4, numberType: true, length: 10, color: 'red'},
            ]
        }
    ]
}

// 15选5
const fifteenChooseFive = {
    modeIndex: 0,
    modes: [
        {
            label: '单式',
            type: 'single',
            multiPours: true,
            multiTimes: true,
            machineChoose: true,
            showBallsIndex: -1,
            orderNumber: ['a152', 'a1522'],
            autoLotteryNumber: 'a1521',
            startNum: 1,
            balls: [
                {label: '请选择5个号码', type: 'single',multiMin: 6, min: 5, max: 5, numberType: true, length: 15, color: 'red'},
            ]
        },
        {
            label: '复式',
            type: 'multiply',
            tips: '请至少选择6个号码',
            multiPours: false,
            multiTimes: true,
            machineChoose: false,
            showBallsIndex: -1,
            orderNumber: ['a153', 'a1532'],
            autoLotteryNumber: 'a1531',
            startNum: 1,
            chooseNum: 5,
            balls: [
                {label: '请选择6-12个号码', type: 'single', min: 6, max: 12, numberType: true, length: 15, color: 'red'},
            ]
        }
    ]
}

// 七乐彩
const sevenLc = {
    modeIndex: 0,
    modes: [
        {
            label: '单式',
            type: 'single',
            multiPours: true,
            multiTimes: true,
            machineChoose: true,
            showBallsIndex: -1,
            orderNumber: ['a302', 'a306'],
            autoLotteryNumber: 'a3021',
            startNum: 1,
            balls: [
                {label: '请选择7个号码', type: 'single',multiMin: 8, min: 7, max: 7, numberType: true, length: 30, color: 'red'},
            ]
        },
        {
            label: '复式',
            tips: '请至少选择8个号码',
            type: 'multiply',
            multiPours: false,
            multiTimes: false,
            machineChoose: false,
            showBallsIndex: -1,
            orderNumber: 'a303',
            autoLotteryNumber: 'a3031',
            startNum: 1,
            balls: [
                {label: '请至少选择8-12个号码', type: 'single',min: 8, max: 12, numberType: true, length: 30, color: 'red'},
            ]
        }
    ]
}

// 福彩3D
const fc3D = {
    modeIndex: 0,
    modes: [
        {
            label: '直选-单式',
            type: 'single',
            multiPours: true,
            multiTimes: true,
            machineChoose: true,
            showBallsIndex: -1,
            orderNumber: ['a032', 'a038'],
            autoLotteryNumber: 'a0321',
            tip: '每位至少选择一个号码',
            rule: '投注号码与开奖号码相同，且按位相符，即中1040元。如123',
            // label: '每位选择一个号码',
            startNum: 0,
            balls: [
                {label: '百位', type: 'single', min: 1, max: 1, numberType: true, length: 10, color: 'red'},
                {label: '十位', type: 'single', min: 1, max: 1, numberType: true, length: 10, color: 'red'},
                {label: '个位', type: 'single', min: 1, max: 1, numberType: true, length: 10, color: 'red'},
            ]
        },
        {
            label: '组选3-单式',
            type: 'zx3DGroup3',
            multiPours: true,
            multiTimes: true,
            machineChoose: true,
            showBallsIndex: -1,
            genPourNumberName: 'fc3Dzx3',
            orderNumber: ['a034', 'a039'],
            autoLotteryNumber: 'a0341',
            startNum: 0,
            rule: '投注号码与开奖号码相同，顺序不限，即中346元。如335',
            balls: [
                {label: '请选择两个号码', type: 'multiply', min: 2, max: 2, numberType: true, length: 10, color: 'red'},
            ]
        },
        {
            label: '组选6-单式',
            type: 'single',
            multiPours: true,
            multiTimes: true,
            machineChoose: true,
            showBallsIndex: -1,
            orderNumber: ['a034', 'a039'],
            autoLotteryNumber: 'a0341',
            startNum: 0,
            rule: '投注号码与开奖号码相同，顺序不限，即中173元。如235',
            balls: [
                {label: '请选择3个号码', type: 'single', min: 3, max: 3, numberType: true, length: 10, color: 'red'},
            ]
        },
        {
            label: '直选-和值',
            type: 'zxhz',
            multiPours: false,
            multiTimes: false,
            machineChoose: true,
            showBallsIndex: -1,
            orderNumber: 'a036',
            startNum: 0,
            rule: '投注号码与开奖号码相同，且按位相符，即中1040元。如123',
            balls: [
                {label: '请选择1个号码', type: 'single', min: 1, max: 1, numberType: true, length: 28, color: 'red'},
            ]
        },
         // 新3D玩法
         {
            label: '通选',
            type: 'single',
            multiPours: true,
            multiTimes: true,
            machineChoose: true,
            timesNeeded: true,
            showBallsIndex: -1,
            orderNumber: 'a0362',
            // autoLotteryNumber: 'a0361',
            tip: '每位至少选择一个号码',
            rule: '投注号码与当前期开奖号码按位全部相同即中470元；任意两位按位相同即中21元。',
            // label: '每位选择一个号码',
            startNum: 0,
            balls: [
                {label: '百位', type: 'single', min: 1, max: 1, numberType: true, length: 10, color: 'red'},
                {label: '十位', type: 'single', min: 1, max: 1, numberType: true, length: 10, color: 'red'},
                {label: '个位', type: 'single', min: 1, max: 1, numberType: true, length: 10, color: 'red'},
            ]
        },
        {
            label: '大小',
            type: 'single',
            multiPours: false,
            multiTimes: true,
            machineChoose: true,
            showBallsIndex: -1,
            timesNeeded: true,
            orderNumber: 'a0363',
            // autoLotteryNumber: 'a0361',
            // tip: '请选择大小',
            rule: '投注号码相加之和在19~27之间即为大，在0~8之间即为小，单注奖金为6元',
            // label: '每位选择一个号码',
            startNum: 0,
            balls: [
                {label: '请选择大小', type: 'single', min: 1, max: 1, numberType: false, length: 2, color: 'red', labels: ['大', '小']},
            ]
        },
        {
            label: '奇偶',
            type: 'single',
            multiPours: false,
            multiTimes: true,
            machineChoose: true,
            timesNeeded: true,
            showBallsIndex: -1,
            orderNumber: 'a0364',
            // autoLotteryNumber: 'a0361',
            // tip: '请选择奇偶',
            rule: '投注号码全部为奇数即为奇(例如：135),全部为偶数即为偶(例如：246),单注奖金为8元。',
            // label: '每位选择一个号码',
            startNum: 0,
            balls: [
                {label: '请选择奇偶', type: 'single', min: 1, max: 1, numberType: false, length: 2, color: 'red', labels: ['奇', '偶']},
            ]
        },
        {
            label: '1D单式',
            type: '1D',
            multiPours: true,
            multiTimes: true,
            machineChoose: true,
            timesNeeded: true,
            showBallsIndex: -1,
            orderNumber: 'a0365',
            machineChooseLen: 1,
            // autoLotteryNumber: 'a0361',
            tip: '每位至少选择一个号码',
            rule: '投注号码与开奖号码中对应位置的号码相同，即中10元。',
            // label: '每位选择一个号码',
            startNum: 0,
            balls: [
                {label: '百位', type: 'single', min: 1, max: 10, numberType: true, length: 10, color: 'red'},
                {label: '十位', type: 'single', min: 1, max: 10, numberType: true, length: 10, color: 'red'},
                {label: '个位', type: 'single', min: 1, max: 10, numberType: true, length: 10, color: 'red'},
            ]
        },
        {
            label: '2D单式',
            type: '2D',
            multiPours: true,
            multiTimes: true,
            machineChoose: true,
            timesNeeded: true,
            showBallsIndex: -1,
            orderNumber: 'a0366',
            machineChooseLen: 2,
            // autoLotteryNumber: 'a0361',
            tip: '每位至少选择一个号码',
            rule: '投注号码与开奖号码中对应两个位置的号码相同，即中104元。',
            // label: '每位选择一个号码',
            startNum: 0,
            balls: [
                {label: '百位', type: 'single', min: 1, max: 10, numberType: true, length: 10, color: 'red'},
                {label: '十位', type: 'single', min: 1, max: 10, numberType: true, length: 10, color: 'red'},
                {label: '个位', type: 'single', min: 1, max: 10, numberType: true, length: 10, color: 'red'},
            ]
        },
        {
            label: '和数单式',
            type: 'single',
            multiPours: true,
            multiTimes: true,
            machineChoose: true,
            timesNeeded: true,
            showBallsIndex: -1,
            orderNumber: 'a0367',
            // autoLotteryNumber: 'a0361',
            tip: '每位至少选择一个号码',
            rule: '投注号码与当期开奖号码的三个号码相加之和相同，即中14~1040元。',
            // label: '每位选择一个号码',
            startNum: 0,
            balls: [
                {label: '百位', type: 'single', min: 1, max: 1, numberType: true, length: 28, color: 'red'},
            ]
        },
        {
            label: '包选三-单式',
            type: 'bx3',
            multiPours: true,
            multiTimes: true,
            machineChoose: true,
            showBallsIndex: -1,
            timesNeeded: true,
            orderNumber: 'a0369',
            repeat: 2,
            // autoLotteryNumber: 'a0368',
            tip: '每位选择一个号码',
            rule: '投注号码中任意两个数字相同，且与开奖号码全部按位相同即中693元，号码相同顺序不同176元。',
            // label: '每位选择一个号码',
            startNum: 0,
            balls: [
                {label: '百位', type: 'single', min: 1, max: 1, numberType: true, length: 10, color: 'red'},
                {label: '十位', type: 'single', min: 1, max: 1, numberType: true, length: 10, color: 'red'},
                {label: '个位', type: 'single', min: 1, max: 1, numberType: true, length: 10, color: 'red'},
            ]
        },
        {
            label: '包选六-单式',
            type: 'single',
            multiPours: true,
            multiTimes: true,
            machineChoose: true,
            showBallsIndex: -1,
            timesNeeded: true,
            repeat: 1,
            orderNumber: 'a0369',
            // autoLotteryNumber: 'a0368',
            tip: '每位选择一个号码，所选号码不能重复',
            rule: '投注号码中三个数字各不相同，且与开奖号码全部按位相同即中606元；号码相同顺序不同86元。',
            // label: '每位选择一个号码',
            startNum: 0,
            balls: [
                {label: '百位', type: 'single', min: 1, max: 1, numberType: true, length: 10, color: 'red'},
                {label: '十位', type: 'single', min: 1, max: 1, numberType: true, length: 10, color: 'red'},
                {label: '个位', type: 'single', min: 1, max: 1, numberType: true, length: 10, color: 'red'},
            ]
        },
        {
            label: '猜三同',
            type: 'single',
            multiPours: false,
            multiTimes: true,
            machineChoose: true,
            timesNeeded: true,
            showBallsIndex: -1,
            orderNumber: 'a0370',
            // autoLotteryNumber: 'a0368',
            tip: '每位至少选择一个号码',
            rule: '开奖号码为三同中任意一个号码，即中104元。',
            // label: '每位选择一个号码',
            startNum: 0,
            balls: [
                {label: '同号', type: 'single', min: 1, max: 1, numberType: true, length: 1, color: 'red'},
            ],
            chooseAll: ['000','111','222','333','444','555','666','777','888','999']
        },
        {
            label: '拖拉机',
            type: 'single',
            multiPours: false,
            multiTimes: true,
            machineChoose: true,
            timesNeeded: true,
            showBallsIndex: -1,
            orderNumber: 'a0371',
            // autoLotteryNumber: 'a0368',
            tip: '每位至少选择一个号码',
            rule: '开奖号码的三个号码为以升序或降序连续排列的号码（890、098、901、109除外），即中65元。',
            // label: '每位选择一个号码',
            startNum: 0,
            balls: [
                {label: '同号', type: 'single', min: 1, max: 1, numberType: true, length: 1, color: 'red'},
            ],
            chooseAll: ['012', '123', '234', '345', '456', '567', '678', '789', '210', '321', '432', '543', '654', '765', '876', '987'],
        },
    ]
}

export const auotLotteryNotTimes = [
    'a04141'
]

export const chooseNumberArr = new Map()
chooseNumberArr.set(11, ssqInfo)
chooseNumberArr.set(51, fc3D)
chooseNumberArr.set(24, sevenLc)
chooseNumberArr.set(61, sixPlusOne)
chooseNumberArr.set(52, cx4)
chooseNumberArr.set(23, fifteenChooseFive)

export const chooseNumberArrMachine = new Map()
chooseNumberArrMachine.set(11, ssqInfoMachine)
chooseNumberArrMachine.set(51, fc3DInfoMachine)
chooseNumberArrMachine.set(24, sevenLcMachine)
chooseNumberArrMachine.set(61, sixPlusOneMachine)
chooseNumberArrMachine.set(52, cx4Machine)
chooseNumberArrMachine.set(23, fifteenChooseFiveMachine)



export const messageOrder = [
    {
        name: '双色球',
        title: '双色球短信指令',
        tip: '指令首字母a，使用大小写均可',
        orders: [
            {
                name: '单式机选',
                order: 'a3361#机选注数',
                example: '机选3注，指令为：a3361#3'
            },
            {
                name: '单式自选',
                order: 'a3362#单式号码1#单式号码2…',
                tips: '红球和蓝球间没有间隔，最后一个号码默认为蓝球。一条短信可以自选1－5注号码。',
                example: '自选2注号码，指令为：a3362#01020304050607#28293031323301'
            },
            {
                name: '单式自选倍投',
                order: 'a33622#单式号码#倍数',
                tips: '红球和蓝球间没有间隔，最后一个号码默认为蓝球。一条短信仅能倍投1注号码。',
                example: '自选1注号码，购买2倍，指令为：a33622#01020304050607#2'
            },
            {
                name:'复式 ',
                order:'a3363#红球号码#蓝球号码',
                tips:'（可自选7－12个红球和1－16个蓝球。一条短信仅能自选1注复式号码。）',
                example:'例：自选1注复式号码，指令为： a3363#0102030405060708#070809 ',
            },
            {
                name:'复式倍投 ',
                order:'a33632#红球号码#蓝球号码#倍数',
                tips:'（可自选7－12个红球和1－16个蓝球。一条短信仅能自选1注复式号码。）',
                example:'例：自选1注复式号码，购买2倍，指令为：a33632#01020304050607#0708#2 ',
            },
            {
                name:'机选自动 ',
                order:'a33611#机选注数',
                tips:'（系统每期自动为用户购买相应注数的机选号码，直到卡内资金不足或用户取消自动为止。自动投注指令下期生效。）',
                example:'例：设定每期机选4注，指令为：a33611#4 ',
            },
            {
                name:'单式自选自动 ',
                order:'a33621#单式号码#倍数（指令不加#倍数则默认为1倍）',
                tips:'（红球和蓝球间没有间隔，最后一个号码默认为蓝球。系统每期自动为用户购买该自选号码，直到卡内资金不足或用户 取消自动为止。一条短信仅能设定1注号码。自动投注指令下期生效。）',
                example:'例：设定每期购买1注单式自选号码，且购买3倍，指令为：a33621#01020304050607#3 ',
            },
            {
                name:'复式自选自动 ',
                order:'a33631#红球号码#蓝球号码#倍数（指令不加#倍数则默认为1倍）',
                tips:'（可自选7－12个红球和1－16个蓝球。系统每期自动为用户购买该自选号码，直到卡内资金不足或用户取消自动为止。 一条短信仅能设定1注号码。自动投注指令下期生效。）',
                example:'例：设定每期购买1注复式自选号码，且购买3倍，指令为：a33631#01020304050607#101112#3',
            },
            {
                name:'取消自动 ',
                order: 'a3365',
                example:'如已设定多条自动投注，发送取消指令后，所有双色球的自动投注全部取消。取消指令下期生效。 ',
            },
        ]
    },
    {
        name: '双色球多期',
        title: '双色球多期短信指令',
        tip: '指令首字母a，使用大小写均可',
        orders: [
               {
                name:'机选包周 ',
                order: 'a3361TC01',
                example:'例：机选3期，指令为：a3361TC01 ',
                },
                {
                name:'机选包月 ',
                order:'a3361TC02',
                tips:'a3361TC02',
                example:'例：机选13期，指令为：a3361TC02 ',
                },
                {
                name:'机选包季 ',
                order:'a3361TC03',
                tips:'a3361TC03',
                example:'例：机选39期，指令为：a3361TC03 ',
                },
                {
                name:'机选包半年 ',
                order:'a3361TC04',
                tips:'a3361TC04',
                example:'例：机选78期，指令为：a3361TC04 ',
                },
                {
                name:'机选包年 ',
                order:'a3361TC05',
                tips:'a3361TC05',
                example:'例：机选150期，指令为：a3361TC05 ',
                },
                {
                name:'自选包周 ',
                order: 'a3362TC01',
                example:'例：自选3期，指令为：a3362TC01#01020304050607 ',
                },
                {
                name:'自选包月 ',
                order:'a3362TC02#单式号码',
                tips:'a3362TC02#单式号码',
                example:'例：自选13期，指令为：a3362TC02#01020304050607 ',
                },
                {
                name:'自选包季 ',
                order:'a3362TC03#单式号码',
                tips:'a3362TC03#单式号码',
                example:'例：自选39期，指令为：a3362TC03#01020304050607 ',
                },
                {
                name:'自选包半年 ',
                order:'a3362TC04#单式号码',
                tips:'a3362TC04#单式号码',
                example:'例：自选78期，指令为：a3362TC04#01020304050607 ',
                },
                {
                name:'自选包年 ',
                order:'a3362TC05#单式号码',
                tips:'a3362TC05#单式号码',
                example:'例：自选150期，指令为：a3362TC05#01020304050607 ',
                },
        ]
    },
    {
        name: '福彩3D',
        title: '3D短信指令',
        tip: '指令首字母a，使用大小写均可',
        orders: [
            {
                name:'通选机选',
                order: 'a0361#机选注数#倍数(倍投不能省略)',
                example:'例：通选机选3注1倍号码，指令为：a0361#3#1 ',
                },
                {
                name:'通选自选',
                order:'a0362#号码1#号码2#号码3…#倍数(倍投不能省略)',
                tips:'（一条短信可以自选1－5注号码）',
                example:'例：通选自选2注1倍号码，指令为：a0362#125#346#1 ',
                },
                {
                name:'猜大小 ',
                order:'a0363#号码#倍数(倍投不能省略)',
                tips:'2：大）',
                example:'例：猜1注当期开奖号码之和是小的1倍号码，指令为：a0363#1#1 ',
                },
                {
                name:'猜奇偶 ',
                order:'a0364#号码#倍数(倍投不能省略)',
                tips:'5：奇）',
                example:'例：猜1注当期开奖号码是偶数的1倍号码，指令为：a0364#4#1 ',
                },
                {
                name:'1D单式 ',
                order:'a0365#号码1#号码2#号码3…#倍数(倍投不能省略)',
                tips:'（一条短信可以自选1－5注号码,每个号码3位，不选部分用a代替）',
                example:'例：猜2注分别是十位数为1，百位数为5的1倍号码，指令为：a0365#a1a#5aa#1',
                },
                {
                name:'2D单式 ',
                order:'a0366#号码1#号码2#号码3…#倍数(倍投不能省略)',
                tips:'（一条短信可以自选1－5注号码，每个号码3位，不选部分用a代替）',
                example:'例：猜2注十位个位分别为1和2，百位个位分别为5和7的1倍号码，指令为：a0366#a12#5a7#1',
                },
                {
                name:'和数单式 ',
                order:'a0367#号码1#号码2#号码3…#倍数(倍投不能省略)',
                tips:'（一条短信可以自选1－5注号码，号码为0~27中的任意一个）',
                example:'例：自选2注和值分别是6和24的1倍号码，指令为：a0367#6#24#1',
                },
                {
                name:'包选机选 ',
                order: 'a0368#机选注数#倍数(倍投不能省略)',
                example:'例：包选机选4注1倍号码，指令为：a0368#4#1 ',
                },
                {
                name:'包选自选 ',
                order: 'a0369#号码1#号码2#号码3…#倍数(倍投不能省略)',
                example:'例：包选自选2注1倍号码，指令为：a0369#345#278#1 ',
                },
                {
                name:'猜三同 ',
                order:'a0370#号码#倍数(倍投不能省略)',
                tips:'（一条短信仅支持一注号码，固定为aaa）',
                example:'例：投注号码固定为aaa，指令为：a0370#aaa#1 ',
                },
                {
                name:'拖拉机 ',
                order:'a0371#号码#倍数(倍投不能省略)',
                tips:'（一条短信仅支持一注号码，固定为abc）',
                example:'例：投注号码固定为abc，指令为：a0371#abc#1 ',
                },
                {
                name:'单式机选 ',
                order: 'a031#机选注数',
                example:'例：机选3注直选号码，指令为：a031#3 ',
                },
                {
                name:'组选机选 ',
                order:'a033#机选注数',
                tips:'a033#机选注数',
                example:'例：机选3注组选号码，指令为：a033#3 ',
                },
                {
                name:'直选单式 ',
                order:'a032#直选号码1#直选号码2…',
                tips:'（一条短信可以自选1－5注号码。）',
                example:'例：自选2注直选号码，指令为：a032#012#123 ',
                },
                {
                name:'组选单式 ',
                order:'a034#组选号码1#组选号码2…',
                tips:'（一条短信可以自选1－5注号码。）',
                example:'例：自选2注组选号码，指令为：a034#012#123 ',
                },
                {
                name:'直选单式倍投 ',
                order:'a038#直选号码#倍数',
                tips:'（一条短信仅能倍投1注号码。倍数上限为50倍。）',
                example:'例：自选1注直选号码，购买50倍，指令为：a038#012#50 ',
                },
                {
                name:'组选单式倍投 ',
                order:'a039#组选号码#倍数',
                tips:'（一条短信仅能倍投1注号码。倍数上限为50倍。）',
                example:'例：自选1注组选号码，购买50倍，指令为：a039#012#50 ',
                },
                {
                name:'直选和值 ',
                order:'a036#和值',
                tips:'（和值范围0－27。一条短信仅能选择1个和值。）',
                example:'例：购买和值为9的所有直选号码，指令为：a036#9 ',
                },
                {
                name:'直选机选自动 ',
                order:'a0311#机选注数',
                tips:'（系统每期自动为用户购买相应注数的机选号码，直到卡内资金不足或用户取消自动为止。自动投注指令下期生效。）',
                example:'例：设定每期机选4注直选号码，指令为：a0311#4 ',
                },
                {
                name:'组选机选自动 ',
                order:'a0331#机选注数',
                tips:'（系统每期自动为用户购买相应注数的机选号码，直到卡内资金不足或用户取消自动为止。自动投注指令下期生效。）',
                example:'例：设定每期机选4注组选号码，指令为：a0331#4 ',
                },
                {
                name:'直选自选自动 ',
                order:'a0321#直选号码#倍数（指令不加#倍数则默认为1倍）',
                tips:'（系统每期自动为用户购买该自选号码，直到卡内资金不足或用户取消自动为止。一条短信仅能设定1注号码。自动投注指令下期生效。）',
                example:'例：设定每期购买1注直选自选号码，且购买3倍，指令为：a0321#123#3 ',
                },
                {
                name:'组选自选自动 ',
                order:'a0341#组选号码#倍数（指令不加#倍数则默认为1倍）',
                tips:'（系统每期自动为用户购买该自选号码，直到卡内资金不足或用户取消自动为止。一条短信仅能设定1注号码。自动投注指令下期生效。）',
                example:'例：设定每期购买1注组选自选号码，且购买3倍，指令为：a0341#123#3 ',
                },
                {
                name:'取消自动 ',
                order: 'a035',
                example:'如已设定多条自动投注，发送取消指令后，所有3D的自动投注全部取消。取消指令下期生效。 ',
                },
        ]
    },
    {
        name: '七乐彩',
        title: '七乐彩短信指令',
        tip: '指令首字母a，使用大小写均可',
        orders: [
                {
                name:'单式机选 ',
                order: 'a301#机选注数',
                example:'例：机选3注，指令为：a301#3 ',
                },
                {
                name:'单式自选 ',
                order:'a302#单式号码1#单式号码2…',
                tips:'（一条短信可以自选1－5注号码。）',
                example:'例：自选2注号码，指令为：a302#01020304050607#10111213141516',
                },
                {
                name:'单式自选倍投 ',
                order:'a306#单式号码#倍数',
                tips:'（一条短信仅能倍投1注号码。）',
                example:'例：自选1注号码，购买2倍，指令为：a306#01020304050607#2 ',
                },
                {
                name:'复式 ',
                order:'a303#复式号码',
                tips:'（可自选8－12个号码。一条短信仅能自选1注复式号码。）',
                example:'例：自选1注复式号码，指令为： a303#010203040506070809 ',
                },
                {
                name:'机选自动 ',
                order:'a3011#机选注数',
                tips:'（系统每期自动为用户购买相应注数的机选号码，直到卡内资金不足或用户取消自动为止。自动投注指令下期生效。）',
                example:'例：设定每期机选4注，指令为：a3011#4 ',
                },
                {
                name:'单式自选自动 ',
                order:'a3021#单式号码#倍数（指令不加#倍数则默认为1倍）',
                tips:'',
                example:'例：设定每期购买1注单式自选号码，且购买3倍，指令为：a3021#01020304050607#3',
                },
                {
                name:'复式自动 ',
                order:'a3031#复式号码#倍数（指令不加#倍数则默认为1倍）',
                tips:'（可自选8－12个号码。系统每期自动为用户购买该自选号码，直到卡内资金不足或用户取消自动为止。一条短信仅能设定1注号码。自动投注指令下期生效。）',
                example:'例：设定每期购买1注复式号码，且购买3倍，指令为：a3031#010203040506070809#3 ',
                },
                {
                    name:'取消自动 ',
                    order: 'a305',
                    example: '如已设定多条自动投注，发送取消指令后，所有七乐彩的自动投注全部取消。取消指令下期生效。'
                }
        ]
    },
    {
        name: '15选5',
        title: '15选5短信指令',
        tip: '指令首字母a，使用大小写均可',
        orders: [
                {
                name:'单式机选 ',
                order: 'a151#机选注数',
                example:'例：机选3注，指令为：a151#3 ',
                },
                {
                name:'单式自选 ',
                order:'a152#单式号码1#单式号码2…',
                tips:'（一条短信可以自选1－5注号码。）',
                example:'例：自选2注号码，指令为：a152#0102030405#1112131415 ',
                },
                {
                name:'单式自选倍投 ',
                order:'a1522#单式号码#倍数',
                tips:'（一条短信仅能倍投1注号码。）',
                example:'例：自选1注号码，购买2倍，指令为：a1522#0102030405#2 ',
                },
                {
                name:'复式 ',
                order:'a153#复式号码',
                tips:'（可自选6－12个号码。一条短信仅能自选1注复式号码。）',
                example:'例：自选1注复式号码，指令为：a153#0102030405060708 ',
                },
                {
                name:'复式倍投 ',
                order:'a1532#复式号码#倍数',
                tips:'）',
                example:'例：自选1注复式号码，购买2倍，指令为：a1532#0102030405060708#2 ',
                },
                {
                name:'机选自动 ',
                order:'a1511#机选注数',
                tips:'（系统每期自动为用户购买相应注数的机选号码，直到卡内资金不足或用户取消自动为止。自动投注指令下期生效。）',
                example:'例：设定每期机选4注，指令为：a1511#4 ',
                },
                {
                name:'单式自选自动 ',
                order:'a1521#单式号码#倍数（指令不加#倍数则默认为1倍）',
                tips:'（系统每期自动为用户购买该自选号码，直到卡内资金不足或用户取消自动为止。一条短信仅能设定1注号码。自动投注指令下期 生效。）',
                example:'例：设定每期购买1注单式自选号码，且购买3倍，指令为：a1521#0102030405#3 ',
                },
                {
                name:'复式自动 ',
                order:'a1531#复式号码#倍数（指令不加#倍数则默认为1倍）',
                tips:'（可自选6－12个号码。系统每期自动为用户购买该自选号码，直到卡内资金不足或用户取消自动为止。一条短信仅能设定1注号码。自动投注指令下期生效。）',
                example:'例：设定每期购买1注复式号码，且购买3倍，指令为：a1531#010203040506070809#3 ',
                },
                {
                    name:'取消自动 ',
                    order: 'a155',
                    example: '如已设定多条自动投注，发送取消指令后，所有15选5的自动投注全部取消。取消指令下期生效。'
                }
        ]
    },
    {
        name: '东方6+1',
        title: '东方6+1短信指令',
        tip: '指令首字母a，使用大小写均可',
        orders: [
                {
                name:'单式机选 ',
                order: 'a061#机选注数',
                example:'例：机选3注，指令为：a061#3 ',
                },
                {
                name:'单式自选 ',
                order:'a062#基本码#生肖码…',
                tips:'（基本码和生肖码间以#隔开。一条短信可以自选1－5注号码。每注号码间以#隔开。）',
                example:'例：自选2注号码，指令为：a062#123456#01#654321#02 ',
                },
                {
                name:'自选倍投 ',
                order:'a0622#基本码#生肖码#倍数',
                tips:'（基本码和生肖码间以#隔开。一条短信仅能设定1注号码。）',
                example:'例：自选1注号码23倍，指令为：a0622#123456#12#23 ',
                },
                {
                name:'机选自动 ',
                order:'a0611#机选注数',
                tips:'（系统每期自动为用户购买相应注数的机选号码，直到卡内资金不足或用户取消自动为止。自动投注指令下期生效。）',
                example:'例：设定每期机选4注，指令为：a0611#4 ',
                },
                {
                name:'单式自动 ',
                order:'a0621#基本码#生肖码#倍数（指令不加#倍数则默认为1倍）',
                tips:'（系统每期自动为用户购买该自选号码，直到卡内资金不足或用户取消自动为止。一条短信仅能设定1注号码。自动投注指令下期 生效。）',
                example:'例：设定每期购买1注单式自选号码，并购买3倍，指令为：a0621#123456#05#3 ',
                },
                {
                    name:'取消自动 ',
                    order: 'a065',
                    example: '如已设定多条自动投注，发送取消指令后，所有东方6+1的自动投注全部取消。取消指令下期生效。'
                }
        ]
    },
    {
        name: '天天彩选4',
        title: '天天彩选4短信指令',
        tip: '指令首字母a，使用大小写均可',
        orders: [
                {
                name:'直选机选 ',
                order: 'a0411#机选注数',
                example:'例：机选3注直选号码，指令为：a0411#3',
                },
                {
                name:'直选自选 ',
                order:'a0412#自选号码',
                tips:'',
                example:'例：自选2注直选号码，指令为：a0412#0124#1236',
                },
                {
                name:'组选机选 ',
                order: 'a0413#机选注数',
                example:'例：机选3注组选号码，指令为：a0413#3',
                },
                {
                name:'组选自选 ',
                order:'a0414#自选号码',
                tips:'',
                example:'例：自选2注组选号码，指令为：a0414#0124#1236',
                },
                {
                name:'直选机选多期',
                order:'a04111#机选注数',
                tips:'（系统每期自动为用户购买相应注数的机选号码，直到卡内资金不足或用户取消自动为止。自动投注指令下期生效。）',
                example:'例：设定每期机选4注直选号码，指令为：a04111#4 ',
                },
                {
                name:'直选自选多期',
                order:'a04121#直选号码#倍数',
                tips:'（系统每期自动为用户购买该自选号码，直到卡内资金不足或用户取消自动为止。一条短信仅能设定1注号码。自动投注指令下期 生效。）',
                example:'例：设定每期购买1注直选自选号码，购买2倍。指令为：a04121#1234#2',
                },
                {
                name:'组选机选多期',
                order:'a04131#机选注数',
                tips:'（系统每期自动为用户购买相应注数的机选号码，直到卡内资金不足或用户取消自动为止。自动投注指令下期生效。）',
                example:'例：设定每期机选4注组选号码，指令为：a04131#4 ',
                },
                {
                name:'组选自选多期',
                order:'a04141#组选号码',
                tips:'（系统每期自动为用户购买该自选号码，直到卡内资金不足或用户取消自动为止。一条短信仅能设定1注号码。自动投注指令下期 生效。）',
                example:'例：设定每期购买1注组选自选号码，指令为：a04141#1233',
                },
                {
                    name:'取消自动 ',
                    order: 'a045',
                    example: '如已设定多条自动投注，发送取消指令后，所有天天彩选4的自动投注全部取消。取消指令下期生效。'
                }
        ]
    },
    {
        name: '其他指令',
        title: '其他指令',
        tip: '',
        orders: [
                {
                name:'卡绑定',
                order:'002#投注卡号或已开通投注功能的银行卡号#投注密码',
                tips:'',
                example:'绑定投注卡后，该手机与其他投注卡的绑定关系将解除。',
                },
                {
                name:'解除绑定',
                order:'003#投注卡号或已开通投注功能的银行卡号#投注密码',
                tips:'',
                example:'',
                },
                {
                name:'投注卡转值',
                order:'007#转入卡卡号#转出卡卡号#转出卡密码',
                tips:'',
                example:'只有开户时输入的身份证号码相同的投注卡之间可互相转值。',
                },
              
         ]
}
]

export const storeIdAuthor = [
    {
        storeId: '81666', // 8166电子卡
        hiddenEvents: [],
    },
    {
        storeId: '624', // 建行
        hiddenEvents: [],
    },
    {
        storeId: '613', // 农行
        hiddenEvents: ['recharge', 'rechargeQRCode', 'bankCards', 'drawback'],
    },
    {
        storeId: '611', // 交行
        hiddenEvents: ['recharge', 'rechargeQRCode', 'bankCards', 'drawback'],
    },
    {
        storeId: ['619', '614', '612', '625'], // 工行
        hiddenEvents: ['bankCards', 'drawback'],
    },
]

export const fundKinds = [
    {id: 'IMPREST', text: '充值', bgColor: 'fb941b', img: require('../assets/imgs/account_icon/recharge.png')},
    {id: 'RECOVERY', text: '过期红包', bgColor: 'bdbdbd', img: require('../assets/imgs/account_icon/expired_red_envelope.png')},
    {id: 'RETREAT', text: '退款', bgColor: '58b21d', img: require('../assets/imgs/account_icon/refund.png')},
    {id: 'AGENCYBUY', text: '普通投注（扣款）',bgColor: 'e80c16', img: require('../assets/imgs/account_icon/ordinary_betting.png')},
    {id: 'AUTOBUY', text: '自动多期（扣款）',bgColor: 'e80c16', img: require('../assets/imgs/account_icon/multi_period.png')},
    {id: 'AUTOBUY306', text: '多期投注（扣款）',bgColor: 'e80c16', img: require('../assets/imgs/account_icon/multi_period.png')},
    {id: 'DISPATCH', text: '返奖',bgColor: 'ff7814', img: require('../assets/imgs/account_icon/reward_return.png')},
    {id: 'DRAWING', text: '提现',bgColor: '2b74e3', img: require('../assets/imgs/account_icon/withdrawal.png')},
    {id: 'DRAWFAILURE', text: '提现失败退款',bgColor: '58b21d', img: require('../assets/imgs/account_icon/refund.png')},
    {id: 'MANUAREPARATIONS', text: '人工赔款',bgColor: '503ed8', img: require('../assets/imgs/account_icon/manual_compensation.png')},
    {id: 'POINTEXCHANGE', text: '积分兑换',bgColor: '9552db', img: require('../assets/imgs/account_icon/redemption_of_points.png')},
    {id: 'PRESENT', text: '活动赠金',bgColor: 'ffbe0d', img: require('../assets/imgs/account_icon/event_gift.png')},
    {id: 'BETCARDTRANS', text: '投注卡转账',bgColor: 'ba8127', img: require('../assets/imgs/account_icon/transfer_by_betting_card.png')},
    {id: 'CORRACC', text: '平账',bgColor: 'fbae1b', img: require('../assets/imgs/account_icon/balancing_account.png')},
    {id: 'MANUACHARG', text: '人工冲正退款',bgColor: '58b21d', img: require('../assets/imgs/account_icon/refund.png')},
    {id: 'OVERDUE', text: '赠金失效',bgColor: 'bdbdbd', img: require('../assets/imgs/account_icon/gift_invalid.png')},
    {id: 'REDCOUPON', text: '赠送红包',bgColor: 'ec4a25', img: require('../assets/imgs/account_icon/expired_red_envelope.png')},
    {id: 'SENDLOTTERY', text: '送彩票用户资金',bgColor: 'fbae1b', img: require('../assets/imgs/account_icon/sending_funds.png')},
    {id: 'SENDLOTTERYDEDUCT', text: '领彩票扣款',bgColor: 'e80c16', img: require('../assets/imgs/account_icon/deduction.png')},
    {id: 'SENDLOTTERYREFUND', text: '送彩票退款',bgColor: '58b21d', img: require('../assets/imgs/account_icon/refund.png')},
    {id: 'LOCALTRANSFER', text: '本账户资金互转',bgColor: '22a6df', img: require('../assets/imgs/account_icon/mutual_transfer_of_funds.png')},
]

export const redFundKinds = ['RETREAT', 'DRAWFAILURE', 'MANUAREPARATIONS', 'MANUACHARG', 'SENDLOTTERYREFUND']

export * from './fundDetail'