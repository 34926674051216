import { createRouter, createWebHistory} from 'vue-router'
import { baseRoute , setIsHome, getTagID} from './utils/util'

const _baseRoute = baseRoute()

const routes = [
    {
        path: `${_baseRoute}`, 
        meta: {
            title: '福彩电话投注'
        },
        component: () => import('./pages/index.vue'),
        children: [
            {
                path: '',
                meta: {
                    title: '福彩电话投注',
                },
                component: () => import('./pages/home.vue')
            },
            {
                path: 'termly',
                meta: {
                    title: '多期产品',
                },
                component: () => import('./pages/constLottery/index.vue')
            },
            {
                path: 'mine',
                meta: {
                    title: '个人中心'
                },
                query: {
                    p: 123
                },
                component: () => import('./pages/mine.vue')
            },
            {
                path: 'message-order',
                meta: {
                    title: '短信指令',
                    // keepAlive: true
                },
                component: () => import('./pages/messageOrder.vue')
            }
        ]
    },
    {
        path: `${_baseRoute}/saveDesk`, 
        meta: {
            title: '保存到桌面'
        },
        component: () => import('./pages/saveDesk/index.vue')
    },
    {
        path: `${_baseRoute}/applyPhone`, 
        meta: {
            title: '申请手机号'
        },
        component: () => import('./pages/applyPhoneNumber/index.vue')
    },
    {
        path: `${_baseRoute}/sms`, 
        meta: {
            title: '引导页'
        },
        component: () => import('./pages/sms/index.vue')
    },
    {
        path: `${_baseRoute}/constLottery`, 
        meta: {
            title: '多期产品',
            keepAlive: true
        },
        component: () => import('./pages/constLottery/index.vue')
    },
    {
        path: `${_baseRoute}/withdrawSuccess`, 
        meta: {
            title: '提现成功'
        },
        component: () => import('./pages/submine/withdrawSuccess.vue')
    },
    {
        path: `${_baseRoute}/choosenumber`, 
        meta: {
            title: '选号'
        },
        component: () => import('./pages/chooseNumber.vue')
    },
    {
        path: `${_baseRoute}/lotterylist`, 
        meta: {
            title: '选号单'
        },
        component: () => import('./pages/lotteryList.vue')
    },
    {
        path: `${_baseRoute}/changePassword`, 
        meta: {
            title: '密码修改'
        },
        component: () => import('./pages/submine/changePassword.vue')
    },
    {
        path: `${_baseRoute}/accountSafe`, 
        meta: {
            title: '账户安全'
        },
        component: () => import('./pages/submine/AccountSafe.vue')
    },
    {
        path: `${_baseRoute}/fillInfo`, 
        meta: {
            title: '信息完善'
        },
        component: () => import('./pages/submine/realNameAuthor.vue')
    },
    {
        path: `${_baseRoute}/withdraw`, 
        meta: {
            title: '提现'
        },
        component: () => import('./pages/submine/withdraw.vue')
    },
    {
        path: `${_baseRoute}/wincode`, 
        meta: {
            title: '开奖号码'
        },
        component: () => import('./pages/winCode.vue')
    },
    {
        path: `${_baseRoute}/wincodeList`, 
        meta: {
            title: '最近开奖'
        },
        component: () => import('./pages/subIndex/bonusList.vue')
    },
    {
        path: `${_baseRoute}/lotteryRecard`, 
        meta: {
            title: '投注记录',
            keepAlive: true
        },
        component: () => import('./pages/submine/lotteryRecard.vue')
    },
    {
        path: `${_baseRoute}/lotteryDetail`, 
        meta: {
            title: '投注详情'
        },
        component: () => import('./pages/submine/lotteryDetail.vue')
    },
    {
        path: `${_baseRoute}/bankCards`, 
        meta: {
            title: '我的银行卡'
        },
        component: () => import('./pages/submine/bankCards.vue')
    },
    {
        path: `${_baseRoute}/addBankCard`, 
        meta: {
            title: '添加银行卡'
        },
        component: () => import('./pages/submine/addBankCard.vue')
    },
    {
        path: `${_baseRoute}/betSuccess`, 
        meta: {
            title: '投注成功'
        },
        component: () => import('./pages/betSuccess.vue')
    },
    {
        path: `${_baseRoute}/contact`, 
        meta: {
            title: '联系我们'
        },
        component: () => import('./pages/submine/contact.vue')
    },
    {
        path: `${_baseRoute}/qrcode`, 
        meta: {
            title: '充值二维码'
        },
        component: () => import('./pages/submine/QRCode.vue')
    },
    {
        path: `${_baseRoute}/complain`, 
        meta: {
            title: '电话投注使用说明'
        },
        component: () => import('./pages/subIndex/PhoneLotteryComplain.vue')
    },
    {
        path: `${_baseRoute}/newinfo`, 
        meta: {
            title: '最新资讯'
        },
        component: () => import('./pages/subIndex/newInfo.vue')
    },
    {
        path: `${_baseRoute}/newDetail`, 
        meta: {
            title: '详情'
        },
        component: () => import('./pages/subIndex/newsDetail.vue')
    },
    {
        path: `${_baseRoute}/login`,
        meta: {
            title: '登录'
        },
        component: () => import('./pages/login.vue')
    },
    {
        path: `${_baseRoute}/recharge`,
        meta: {
            title: '充值'
        },
        component: () => import('./pages/recharge.vue')
    },
    {
        path: `${_baseRoute}/recharge-recard`,
        meta: {
            title: '资金明细'
        },
        component: () => import('./pages/rechargeRecard.vue')
    },
    {
        path: `${_baseRoute}/news`,
        meta: {
            title: '详情'
        },
        component: () => import('./pages/news.vue')
    },
    {
        path: `${_baseRoute}/alipay`,
        meta: {
            title: '充值'
        },
        component: () => import('./pages/aliPay.vue')
    },
    {
        path: `${_baseRoute}/test`,
        meta: {
            title: '测试'
        },
        component: () => import('./pages/teak.vue')
    },
    {
        path: `${_baseRoute}/404`,
        component: () => import('./pages/notFound.vue')
    },
    {
        path: '/:catchAll(.*)',
        redirect: `${_baseRoute}/404`
    }

]

const router = createRouter({
    history: createWebHistory(),
    base:`${_baseRoute}`,
    routes
})

router.beforeEach((to, from, next) => {
    if(to.path !== from.path) {
        document.title = to.meta.title 
        if (to.path == '/' || to.path == `${_baseRoute}` || to.path == `${_baseRoute}/mine`) {
            setIsHome(true)
        } else {
            setIsHome(false)
        }
    }
    if (to.query.p) {
        next()
    } else {
        const tagID = getTagID()
        next({path: to.path, query: Object.assign(to.query, {p: tagID || '123'})})
    }
})

export default router