import instance from './interceptor'
import { Toast} from 'vant'
Toast.allowMultiple()
let isLoading = false
function request( url, params, method='get', text, loading, forbid) {
    let loadingToast
    if (!isLoading && loading && !loadingToast) {
        isLoading = true
        loadingToast = Toast.loading({
            message: text,
            forbidClick: forbid,
            loadingType: 'spinner',
            duration: 20000
        })
    }
    return new Promise((resolve, reject) => {
        instance({
            url: url,
            method: method,
            params: params,
        }).then((res) => {
            resolve(res)
        }).catch((err) => {
            reject(err)
        }).finally(() => {
            isLoading = false
            if (loading && loadingToast) {
                loadingToast.clear()
            }
        })
    })
}

function get(url, params={},  text = '数据加载中...', loading = true, forbid = true) {
    return request(url, params, 'get', text, loading, forbid)
}

function post(url, params={}, text = '数据加载中...', loading = true, forbid = true) {
    return request(url, params, 'post', text, loading, forbid)
}

export {
    get, post
}