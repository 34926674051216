import { banlance, getWinNumber, balanceAndBonus, userOperation} from '../api'
import { gameIDMap, storeIdAuthor } from '../../common-data'
import router from '../../router'
import { Toast } from 'vant'
import dayjs from 'dayjs'

Toast.allowMultiple()

let userInfo

const dayWeek = [
    [0, '日'],
    [1, '一'],
    [2, '二'],
    [3, '三'],
    [4, '四'],
    [5, '五'],
    [6, '六'],
]

const dayWeekMap = new Map()
dayWeek.forEach(ele => {
    dayWeekMap.set(ele[0], ele[1])
})

// 获取玩法名字
export function getGameName(gameId) {
    return gameIDMap.get(gameId).name
}

// 获取起始日期
// day: D, month: M
export function stardAndEndTime(value) {
    let end = dayjs().toJSON()
    let start = dayjs().subtract(value[0], value[1]).toJSON()
    return {
        start, end
    }
}

// 格式化日期

export function formatTime(time = new Date(), format= 'YYYY-MM-DD HH:mm') {
    return dayjs(time).format(format)
}

// 开奖号码时间
export function openBonusTime(time = new Date(), format= 'YYYY-MM-DD HH:mm') {
    // console.log(time)
    let _time = dayjs(time).format(format)
    let week = dayjs(time).day()
    return `${_time}(周${dayWeekMap.get(week)})`
}

// 格式化开奖号码数组格式
export async function formatWinNumber (len = 6, _numberArr=[]) {
    try {
        const numberArr = _numberArr.length > 0 ? _numberArr : await loadWinNumber(len)
        const _tempNumberArr = []
        numberArr.forEach((ele) => {
            const ball = ele.winningCode.split('#')
            _tempNumberArr.push({
                name: gameIDMap.get(ele.gameId).name,
                url: gameIDMap.get(ele.gameId).icon,
                issueName: ele.issueName,
                gameId: ele.gameId,
                redBall: ball[0].split(','),
                blueBall: ball[1]&&ball[1].split(',') || [],
                prizeDate: ele.prizeDate
            })
        })
        return _tempNumberArr
    } catch(e) {
        console.error(e)
    }
}

// 获取会员店隐藏事件
export function getStoreIdHiddenEvent(storeId) {
    const index =  storeIdAuthor.findIndex((ele, aindex) => {
        if (isArray(ele.storeId)) {
            const _index = ele.storeId.indexOf(storeId)
            return _index > -1 ? aindex : storeIdAuthor.length - 1
        } else {
            return ele.storeId == storeId
        }
        // ele.storeId == storeId || ele.storeId.indexOf(innerId => innerId == storeId)
    })
    return storeIdAuthor[index].hiddenEvents
}

// 是否能充值
export async function canHandleEvent(eventName) {
    try {
        const userInfo = await getUserInfo()
        const storeId = userInfo.storeId
        const hiddenEvent = getStoreIdHiddenEvent(storeId)
        return hiddenEvent.indexOf(eventName) == -1
    } catch(e) {
        console.error(e)
    }
}

// 是否能提现
export async function canWithdraw() {
    return await canHandleEvent('drawback')
}


// 返回上一页
export function goBack(page = -1) {
    console.log(router)
    router.go(page)
}

// 获取开奖号码
export async function loadWinNumber(len) {
    try {
        const result = await getWinNumber()
        const data = result && result.data
        if (result.code == 0 && data && data.length > 0) {
            return data.slice(0, len)
        }
        return []
    } catch(e) {
        console.error(e)
    }
}

// 页面跳转
export function jumpTo(url) {
    router.push(`${baseRoute()}${url}`)
}

// 页面替换
export function replaceTo(url) {
    router.replace(`${baseRoute()}${url}`)
}

// 修改投注列表状态
export function changeLotteryRecardStatus(status = false) {
    console.log(status)
    const route = getCurrentRoute()
    const _route = route.getRoutes().filter(ele => ele.path.indexOf('lotteryRecard') != -1)
    _route[0].meta.keepAlive = status
}

// 获取当前路由
export function getRoute() {
    return router.currentRoute.value.path
}

// 获取当前路径
export function getCurrentRoute() {
    return router
}

// 获取查询字符串
export function getQueryStr(id) {
    const route = router.currentRoute.value
    const strObj = route.query || route.params || {}
    return strObj[id]
}

// set session isHome
export function setIsHome(value) {
    sessionStorage.setItem('isHome', value)
}

// get session isHome
export function getIsHome() {
    return sessionStorage.getItem('isHome')
}

// 登陆
export function login() {
    jumpTo('/login')
}
// toast message
const _Toast = Toast
export function toast(message) {
    _Toast({message: message, duration: 1800})
}

// 复制
export function copyText(text) {
    const dom = document.createElement('input')
    dom.value = text 
    document.body.appendChild(dom)
    dom.select()
    document.execCommand('copy')
    toast('复制成功！')
    document.body.removeChild(dom)
}

// 设置modeIndex
export function setModeIndex(index) {
    sessionStorage.setItem('modeIndex', index)
}

// 获取modeIndex
export function getModeIndex() {
    return sessionStorage.getItem('modeIndex')
}

// 判断是否是开发环境
export function isDev() {
    return process.env.NODE_ENV == 'development'
}

// 设置用户名
export function setUserName(name) {
    sessionStorage.setItem('userName', name)
}

// 获取用户名
export function getUserName() {
    return sessionStorage.getItem('userName')
}

// 获取基础路由
export function baseRoute() {
    // return ''
    // return isDev() ? '' : '/dist'
    return ''
}

// 获取http地址
export function getBaseApi() {
    // return window.httpHref
    // return 'http://192.168.0.174:18028'
    // return 'https://www.81666.net'
    // return isDev() ? '/api' : 'http://192.168.0.174:18028'
    return '/api'
}

// 判断是否登陆
export async function checkIsLogin () {
    try {
        const result = await banlance()
        if (result.code == 0) {
            return true
        } else {
            return false
        }
    } catch(e) {
        console.error(e)
    }
}

 /**
    * 检查设备
*/
const u=navigator.userAgent

// 安卓环境
export function inAndroid () {
    return u.indexOf('Android')>-1||u.indexOf('Adr')>-1
}

// ISO环境
export function inIos() {
    return !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
}

// 支付宝环境
export function inAliPay() {
    return u.match(/AlipayClient/i) == "alipayclient"
}

// 微信环境
export function inWeChat() {
    return u.match(/MicroMessenger/i) == "micromessenger"
}
const phone = '106388106388'
// 发送信息到特服号
export async function sendMessage(content, money=2) {
    if(isWhite) {
        const flag = inAndroid() ? '?' : '&'
        console.log(content, money)
        const userInfo = await getUserInfo()
        // 投注监控事件
        userOperationEvent(5, userInfo.mobile, {smscontent: content, amount: money})
        window.location.href = `sms:${phone}${flag}body=${encodeURIComponent(content+'LX#'+genSoleCode())}`
    } else {
        toast('功能计划中，敬请期待~')
        return
    }
}

// 插入字符
export function insertChar(source, start, newChar) {
    return source.slice(0, start) + newChar + source.slice(start)
}

// 获取随机字符
export function getRandomChar() {
    return String.fromCharCode(getRandomNum(2) ? 65 : 97 + getRandomNum(25))
}

// 生成防重码
export function genSoleCode() {
    // 时间戳
    let str = formatTime(new Date, 'HHmmss')
    // 随机字符
    let i = 2
    while(i) {
        str = insertChar(str, getRandomNum(str?.length), getRandomChar())
        i--
    }
    return str
}

 // 计算注数
 export function pourNumberCount(total, select) {
    // console.log(total, select)
    // if (total < select+1) {
    //     return 0
    // }
    let nA = total, nB = select, cA = 1, cB = 1 
    for (; nB >=1; nA--, nB--) {
        cA = cA * nA 
        cB = cB * nB
    }
    return cA / cB
}

// 睡眠
export function sleep(time = 1000) {
    return new Promise((res) => {
        setTimeout(() => {
            res(true)
        }, time);
    })
}

// 深拷贝
export function deepClone(data) {
    return JSON.parse(JSON.stringify(data))
}


// 特殊投注方式处理（同一种投注方式，单注和倍投指令格式不同）
/**
 * 
 * @param {String} lotteryArr 投注号码
 * @param {Number}} times 投注倍数
 * @param {Array}} orders [0]单倍投注指令 [1]多倍投注指令
 * @returns 
 */
function orderNumberHandle(lotteryArr, times) {
    return function(orders) {
        if (times == 1) {
            return `${orders[0]}${lotteryArr}`
        } else {
            return `${orders[1]}${lotteryArr}#${times}`
        }
    }
}

const labelToValue = [
    ['鼠','01'],
    ['牛','02'],
    ['虎','03'],
    ['兔','04'],
    ['龙','05'],
    ['蛇','06'],
    ['马','07'],
    ['羊','08'],
    ['猴','09'],
    ['鸡','10'],
    ['狗','11'],
    ['猪','12'],
]
export const labelMap = new Map(), valueMap = new Map()
labelToValue.forEach(ele => {
    labelMap.set(ele[0], ele[1])
    valueMap.set(ele[1], ele[0])
})

const textAndValue = [
    ['奇', 5],
    ['偶', 4],
    ['大', 2],
    ['小', 1]
]
export const textToValueMap = new Map(), valueToTextMap = new Map
textAndValue.forEach(ele => {
    textToValueMap.set(ele[0], ele[1])
    valueToTextMap.set(ele[1], ele[0])
})

// 生成投注内容
export function genLotteryInfo(mode) {
    let lotteryArr = ''
    let redArr = '', blueArr = ''
    let ballsLineArr = mode.betNumberArr
    ballsLineArr.forEach((ballsLine) => {
        ballsLine.redBalls && ballsLine.redBalls.forEach(ele => {
            redArr += textToValueMap.has(ele) ? textToValueMap.get(ele) : ele
        })
        ballsLine.blueBalls && ballsLine.blueBalls.forEach(ele => {
            blueArr += labelMap.get(ele) ? labelMap.get(ele) : ele
        })
        lotteryArr += `#${redArr}${mode.split ? '#' : ''}${blueArr}`
        redArr = ''
        blueArr = ''
    })
    return lotteryArr
}

// 判断是否为数组
export function isArray(arr) {
    return Array.isArray(arr)
}


// 生成投注短信
export function genePourNumber(mode, times) {   
    const lotteryArr = genLotteryInfo(mode)
    const handleFun = orderNumberHandle(lotteryArr, times), orderNumber = mode.orderNumber
    if (Array.isArray(orderNumber)) {
        return handleFun(orderNumber)
    } else {
        return mode.orderNumber + lotteryArr + `${ mode.timesNeeded ||  times > 1 ? '#'+ times : ''}`
    }
}

// 排序
export function arrSort(arr) {
    return arr.sort((a, b) => {
        return a - b
    })
}

// 获得随机数组
export function getRandomArr(arrLen, size) {
    let arr = [], i = 0
    for(; i < size; i++) {
        let num = getRandomNum(arrLen)
        num += ''
        if (arr.indexOf(num) == -1) {
            arr.push(num)
        } else {
            i--
        }
    }
    return arrSort(arr)
}

// 获取一个随机数字
function getRandomNum(count) {
    return Math.floor(Math.random() * count)
}

// 获取球球列表
function getBallArr(mode) {
    return mode && mode.balls
}

// 判断球球数组是不是全是1
function isAllOne(balls) {
    let isTrue = true
    balls.forEach(ele => {
        isTrue = ele.min == ele.max == 1
    })
    return isTrue
}

// 获取随机投注数组
export function betRandomArr(mode) {
    const balls = getBallArr(mode)
    let randomArr = new Array(balls.length).fill([])
    // 如果当前模式有获取几列的属性
    if (mode.machineChooseLen) {
        const _arrIndex = getRandomArr(balls.length, mode.machineChooseLen)
        _arrIndex.forEach(index => {
            randomArr[index] = getRandomArr(balls[index].length, balls[index].min)
        })
    } else {
        balls.forEach((ballLine, index) => {
            randomArr[index] = getRandomArr(ballLine.length, ballLine.min)
        })
        const repeatlen = mode.repeat || 0 
        if (isAllOne(balls) && mode.repeat != void 0) {
            const _randomArr = getRandomArr(balls[0].length, balls.length)
            if (repeatlen && repeatlen > 0) {
                // 获取重复数据下标数组，下标0为被重复数据，其它为重复数据
                const repeats = getRandomArr(randomArr.length, repeatlen)
                for (let i = 1; i < repeatlen+1; i++) {
                    _randomArr[repeats[i]] = _randomArr[repeats[0]]
                }
            }
            _randomArr.forEach((ele, index) => {
                randomArr[index] = [ele]
            })
        }
    }
    console.log(randomArr)
    return randomArr
}

// 填充完整数字
export function fillNumber(startNum, i) {
    return startNum == 0 ? i + '' :
           i < 9 ? '0' + (i + 1) :
           (i + 1) + ''
}

// 清空球球选中状态
export function clearChoosedStatus(mode) {
    const balls = getBallArr(mode)
    balls.forEach((ballLine) => {
        ballLine.showBalls.forEach(ball => ball.isChoosed = false)
    })
}

// clear mode 
export function clearMode(mode, clearAll = false, modeIndex = 0) {
    if (clearAll) {
        mode.modes.forEach(ele => {
            ele.betNumberArr = []
            ele.betNumberIndexArr = []
            clearChoosedStatus(ele)
        })
    } else {
        mode.modes[modeIndex].betNumberArr = []
        mode.modes[modeIndex].betNumberIndexArr = []
        clearChoosedStatus(mode.modes[modeIndex])
    }
    mode.modeIndex = modeIndex
    setModeIndex(modeIndex)
}

// 检测输入是否有为空
export function inputIsAllFill(data) {
    for(let i = 0, l = data.length; i < l; i++) {
        let ele = data[i]
        if (ele.value == '' || ele.value == undefined) {
            if (ele.label.indexOf('请') == -1) {
                toast(`${ele.label}不能为空`)
            } else {
                toast(`${ele.label}`)
            }
            return false
        }
    }
    return true
}

// 保存选中号码索引
export function saveLotteryNumberIndexArr(mode, showBallsIndex) {
    const ballLines = getBallArr(mode)
    let lineArr = []
    ballLines.forEach((ballLine) => {
        let ballArr = []
        ballLine.showBalls.forEach((ball, ballIndex) => {
            if(ball.isChoosed) {
                ballArr.push(ballIndex)
            }
        })
        lineArr.push(ballArr)
    })
    mode.betNumberIndexArr = mode.betNumberIndexArr || []
    if (showBallsIndex) {
        mode.betNumberIndexArr[showBallsIndex] = lineArr
    } else {
        mode.betNumberIndexArr.push(lineArr)
    }
}

// 获取选中的红球和篮球
export function getRedAndBlueBalls(mode) {
    const redBalls = [], blueBalls = []
    mode.balls.forEach((ballInfos) => {
        ballInfos.showBalls.forEach((ball) => {
        if (ballInfos.color == 'red' && ball.isChoosed) {
          redBalls.push(ball.value)
          } else if (ballInfos.color == 'blue' && ball.isChoosed) {
          blueBalls.push(ball.value)            
        }})
    })
    return {
        redBalls, blueBalls
    }
}

// 保存通用投注号码
export function saveCommonLotteryNumberArr(mode, showBallsIndex, pour) {
    console.log(showBallsIndex)
    const {redBalls, blueBalls} = getRedAndBlueBalls(mode)
    return [{
        redBalls, blueBalls, pour
    }]
}

// 保存3D-1D号码
export function savefc3D1D(mode) {
    const balls = mode.balls
    let arr = []
    balls.forEach((ele, index) => {
        const choosedEle = ele.showBalls.filter(ele => ele.isChoosed)
        choosedEle.forEach(ball => {
            arr.push({redBalls: index == 0 ? [`${ball.value}`, 'a', 'a'] : index == 1 ? ['a', `${ball.value}`, 'a'] : ['a', 'a', `${ball.value}`]})
        })
    })
    console.log(arr)
    return arr
}

// 获取数组中的重复数据及重复次数
export function getRepeatValueAndCount(arr) {
    const valueMap = new Map(), countMap = new Map()
    arr.forEach((ele => {
      if (valueMap.has(ele)) {
        let value = valueMap.get(ele)
        valueMap.set(ele, value+1)
      } else {
        valueMap.set(ele, 1)
      }
    }))
    valueMap.forEach((value, key) => {
      countMap.set(value, key)
    })
    console.log(valueMap, countMap)
    return {valueMap, countMap}
  }

// 保存3D-2D号码
export function savefc3D2D(mode) {
    const balls = mode.balls
    let ballLines = new Array(balls.length).fill([])
    console.log(ballLines)
    balls.forEach((ele, index) => {
        const choosedBalls = ele.showBalls.filter(ele => ele.isChoosed)
        let _arr = []
        choosedBalls.forEach(ball => _arr.push(ball.value))
        ballLines[index] = _arr
        console.log(index, ballLines[index] )
    })
    const arr = []
    ballLines[0].forEach((firstValue) => {
        ballLines[1].forEach(secValue => {
            arr.push({redBalls: [firstValue, secValue, 'a']})
        })
        ballLines[2].forEach(thirdValue => {
            arr.push({redBalls: [firstValue, 'a', thirdValue]})
        })
    })
    ballLines[1].forEach(secValue => {
        ballLines[2].forEach(thirdValue => {
            arr.push({redBalls: ['a', secValue, thirdValue]})
        })
    })
    return arr
}

// 保存3D-猜三同
export function savefc3DThreeSame() {
    return [{redBalls: ['a', 'a', 'a']}]
}

// 保存3D-拖拉机
export function savefc3DTLJ() {
    return [{redBalls: ['a', 'b', 'c']}]
}



 // 保存福彩3D组选3-单式号码
 export function savefc3DGroup3(mode) {
    let redBalls = [], blueBalls = []
    let chooseBall = mode.balls[0].showBalls.filter(ele => ele.isChoosed)
    let arr = []
    if (chooseBall.length == 2) {  
      const num1 = chooseBall[0].value, num2 = chooseBall[1].value
      redBalls.push(...[num1, num1, num2])
      arr.push({redBalls, blueBalls})
      redBalls = []
      redBalls.push(...[num2, num2, num1])
      arr.push({redBalls, blueBalls})
    }
    return arr
  }

  // 保存天天彩选4组选号码
  function saveCx4(mode) {
      let chooseBall = []
      mode.balls.forEach(ballLine => {
          ballLine.showBalls.forEach(ball => {
              if (ball.isChoosed) {
                  chooseBall.push(ball.value)
              }
          })
      })

      let redBalls = [], blueBalls = []
      const groupNumber = mode.label.match(/\d+/)[0]
      switch (groupNumber) {
        case '4': 
            redBalls.push(...[chooseBall[0], chooseBall[0], chooseBall[0], chooseBall[1]])
            break
        case '6': 
            redBalls.push(...[chooseBall[0], chooseBall[0], chooseBall[1], chooseBall[1]])
            break
        case '12':
            redBalls.push(...[chooseBall[0], chooseBall[0], chooseBall[1], chooseBall[2]])
            break
        case '24':
            redBalls.push(...[chooseBall[0], chooseBall[1], chooseBall[2], chooseBall[3]])
            break
            
      }
      return [{
          redBalls, blueBalls
      }]
  }

  // 获取投注号码
  export function getBetNumber(mode, showBallsIndex, pour) {
      const type = mode.type
      const label = mode.label
      let arr = []
      switch(type) {
        case 'zx3DGroup3':
            arr = savefc3DGroup3(mode, showBallsIndex)
            break
        case 'cx4Group':
            arr = saveCx4(mode, showBallsIndex)
            break
        case '1D':
            arr = savefc3D1D(mode, showBallsIndex)
            break
        case '2D':
            arr = savefc3D2D(mode, showBallsIndex)
            break
        default:
            arr = saveCommonLotteryNumberArr(mode, showBallsIndex, pour)
      
      }
      switch(label) {
        case '猜三同':
            arr = savefc3DThreeSame(mode, showBallsIndex)
            break
        case '拖拉机':
            arr = savefc3DTLJ(mode, showBallsIndex)
            break
        
      }
      return arr
  }



 // 保存投注号码
 function saveBetNumber(mode, showBallsIndex, pour) {
    mode.betNumberArr = mode.betNumberArr || []
    const arr = getBetNumber(mode, showBallsIndex, pour)
    if (showBallsIndex) {
        mode.betNumberArr[showBallsIndex] = arr[0]
    } else {
        mode.betNumberArr.push(...arr)
    }
 }
  // 修改球球状态
  export function changeBallsStatus(selectedBalls, mode) {
    clearChoosedStatus(mode)
    const balls = getBallArr(mode)
    if (selectedBalls) {
        selectedBalls.forEach((line, index) => {
            const ballLine = balls[index].showBalls 
            line.forEach((ball) => {
              ballLine[ball].isChoosed = true
            })
        })
    } else {
        balls.forEach((ballLine) => {
            ballLine.showBalls.forEach(ball => ball.isChoosed = false)
        })
    }
  }
// 随机选择一注，保存选中数据下标
  export function geneBetNumbersIndex(mode) {
      return betRandomArr(mode)
  }


// 添加机选一注，修改显示页面为添加机选的数据，保存选中数据下标及选中数据值
  export function addMachineArr(mode) {
      changeBallsStatus(geneBetNumbersIndex(mode), mode)
      saveLotteryData(mode)
  }
// 机选
  export function machineChoose(mode) {
      changeBallsStatus(geneBetNumbersIndex(mode), mode)
  }
// 保存投注号码
  export function saveLotteryData(mode, showBallsIndex, pour = 1) {
        saveLotteryNumberIndexArr(mode, showBallsIndex)
        saveBetNumber(mode, showBallsIndex, pour)
  }
// 判断是否登录
export async function judgeIsLogin() {
    try {
        const result = await getUserInfo(true)
        if (result && result.realName) {
            return true
        } else {
            // jumpTo('/?login=true')
            return false
        }
    } catch(e) {
        console.error(e)
    }
}

  // 生成自选多期投注号码
//   export function genAutoLotteryNuber(mode) {

//   }

  // 获取用户信息
  export async function getUserInfo(refresh = false) {
      try {
          if (userInfo && !refresh) {
              return userInfo
          } else {
            const result = await balanceAndBonus()
            if (result.code == 0) {
                const data = result.data
                userInfo = data.user
                userInfo.userNo = data.userNo
                userInfo.open = data.open 
                userInfo.url = data.url
                setTagID(userInfo.url)
                setIsWhiteList(userInfo.open == 1) 
                return userInfo
            }
          }
      } catch(e) {
          console.error(e)
      }
  }

  // 设置visitor的值
  export function setVisitor(value) {
      sessionStorage.setItem('visitor', value)
  }

  // 获取visitor的值
  export function getVisitor() {
      return sessionStorage.getItem('visitor') || false
  }

  // 移除visitor
  export function removeVistor() {
      return sessionStorage.removeItem('visitor')
  }

  // 设置手机号
  export function setMobile(value) {
      sessionStorage.setItem('phone', value)
  }

  // 获取手机号
  export function getMobile() {
      return sessionStorage.getItem('phone')
  }

  // 储存session
  export function setSession(name, value) {
      sessionStorage.setItem(name, value)
  }

  // 获取session
  export function getSession(name) {
      return sessionStorage.getItem(name)
  }

  // 设置多期投注tabIndex
  export function setHeadTabIndex(value) {
      return setSession('termlyTabIndex', value)
  }

  // 获取多期投注tabIndex
  export function getHeadTabIndex() {
      return getSession('termlyTabIndex')
  }

  // 设置红包session
  export function setShowRed(value) {
      setSession('redBall', value)
  }

  // 获得红包session
  export function getShowRed() {
      return getSession('redBall')
  }

  // 获取显示指引值
  export function showGuidValue() {
      return localStorage.getItem('showGuid')
  }

  // 设置指引值
  export function setGuidValue(value) {
      localStorage.setItem('showGuid', value)
  }

  // 回到顶部
  export function goTop(value = 0) {
      window.scrollTo(0, value)
    //   if (document.body.scrollTop) {
    //       document.body.scrollTop = value
    //   } else {
    //       document.documentElement.scrollTop = value
    //   }
  }

  // 获取距离顶部距离
  export function getTopValue() {
      return document.body.scrollTop || document.documentElement.scrollTop
  }

  // 是否在奖期
  export function canPour(startTime, endTime) {
      const now = new dayjs()
      const start = new dayjs(startTime)
      const end = new dayjs(endTime)
      return (start < now || start == now) && (now < end || now == end)
  }

  // 获取首页悬浮标识
  export function getSuspendFlag() {
      const value = sessionStorage.getItem('suspend')
      console.log(value)
      if (!value) {
          setSuspendFlag(true)
          return 'true'
      }
      return value
  }

  // 设置首页悬浮标识值
  export function setSuspendFlag(value) {
      sessionStorage.setItem('suspend', value)
  }

  // 格式化金额
  export function formatMoney(num) {
      return (num || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
  }

  // 添加分位
  export function appendCent(num = '0') {
    //   let _num = num
      const _num = num.toString().split('.')
      return ! _num[1] ? `${_num}.00` 
             : _num[1].length == 1 ? `${_num[0]}.${_num[1]}0` 
             : _num[1].length == 2 ?  num
             : `${_num}.00`
  }

  // 函数防抖
export function debounce(fn, delay) {
    return function(args) {
        let _this = this 
        let _args = args 
        clearTimeout(fn.id) 
        fn.id = setTimeout(function() {
            fn.call(_this, _args)
        }, delay)
    }
}

// 是否为15选5
export function isSevenChooseFive(gameId) {
    const isTrue = (gameId == 23) || (gameId == '23')
    console.log(isTrue, gameId)
    return isTrue
}

// 用户行为事件
export function userOperationEvent(type, mobile, content) {
    userOperation({
        mobile: mobile,
        operationType: type,
        content: content
    })
}

let tagID = ''

// 设置标识ID
export function setTagID(id) {
    tagID = id
}

// 获取标识ID
export function getTagID() {
    return tagID
}

let isWhite = false

const insert = {label: '多期投注', route: '/termly', img: require('../../assets/imgs/icon_foot_termly.png'), img2: require('../../assets/imgs/icon_foot_termly_active.png')}
    
let arr = [
    {label: '主页', route: '/', img: require('../../assets/imgs/icon_foot_home.png'), img2: require('../../assets/imgs/icon_foot_home_selected.png')},
    {label: '短信指令', route: '/message-order', img: require('../../assets/imgs/icon_foot_open_price.png'), img2: require('../../assets/imgs/icon_foot_open_price_selected.png')},
    {label: '个人中心', route: '/mine', img: require('../../assets/imgs/icon_foot_mine.png'), img2: require('../../assets/imgs/icon_foot_mine_selected.png')}
]

let arrAll = JSON.parse(JSON.stringify(arr))
arrAll.splice(1, 0, insert)

// 设置是否为白名单
export function setIsWhiteList(value) {
    isWhite = value
}


// 获取底部tab
export function getFootTab() {
   return isWhite ? arrAll : arr
}



