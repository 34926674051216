import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './public/common.scss'
// import Toaster from '@meforma/vue-toaster'
// import {Toast} from 'vant'
import CommonBox from './components/common-box'
import DialogBox from './components/dialog'
import NoData from './components/no-data'
// Toast.allowMultiple()
document.title = '福彩电话投注'
createApp(App).use(router).use(CommonBox).use(NoData).use(DialogBox).mount('#app')
