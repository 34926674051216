import { reactive } from 'vue'
import { chooseNumberArr } from '../common-data'

export function loginData() {
    const state = reactive({
        isLogin: false
    }) 

    const setIsLogin = (value) => {
        state.isLogin = value
    }
    return {
        state,
        setIsLogin
    }
}
export function chooseNumber() {
  const state = reactive({
    11: {},
    51: {},
    24: {},
    52: {},
    23: {},
    61: {}
  })

  // 生成显示数字
  function geneShowNumber(length, startNum = 0) {
    let arr = []
    for(let i = 0 ; i < length; i++) {
      let value = startNum == 0 ? i + '' :
        i < 9 ? '0' + (i + 1) : (i + 1) + ''
      arr.push({value: value , isChoosed: false})
    }
    return arr
  }

  // 生成可用球球数据
  function generateBalls(isNumber, length, labels, startNum) {
    let arr = []
    if (isNumber) {
     arr = geneShowNumber(length, startNum)
    } else {
      labels.forEach(ele => {
        arr.push({value: ele, isChoosed: false})
      })
    }
    return arr
  }

  // 初始化数据
  function initData(key = 11) {
    const _data = chooseNumberArr.get(key)
    if (state[key].modes == void 0) {
      _data.modes.forEach((mode) => {
        mode.balls.forEach((balls) => {
          balls.showBalls = generateBalls(balls.numberType, balls.length, balls.labels, mode.startNum)
        })
      })
    }
    state[key] = _data
  }

   // 获取数据
   function getData(key = 11) {
    key = parseInt(key)
     if (state[key].modes == void 0) {
       initData(key)
     }
    return state[key]
  }
  return {
    state,
    getData,
  }
}