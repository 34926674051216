<template>
    <div class="wrap" v-if="showDialog">
        <div class='dialog'>
            <span class="dialog_close" @click.stop="handleEvent('close')"></span>
            <slot>
                 <div class="dialog_title">
                <h3>{{title}}</h3>
                </div>
                <div class="dialog_content">
                    <p>{{content}}</p>
                </div>
                <ul class="dialog_buttons">
                    <li class="dialog_buttons_item" v-for="(button, index) in buttons" :key="index">
                        <p @click.stop="handleEvent(button.eventName)">{{button.label}}</p>
                    </li>
                </ul>
            </slot>
        </div>
    </div>
</template>
<script lang='ts'>
import { computed, reactive, toRefs } from 'vue'
export default {
    name: '',
    props: {
        title: {
            type: String,
            default: '身份信息完善'
        },
        content: {
            type: String,
            default: '尊敬的用户您好，检测到您的身份证信息还未完善，请前往完善。（身份证是领取大奖的唯一凭证）'
        },
        buttons: {
            type: Array,
            default: () => {
                return [
                    {label: '取消', eventName: 'cancel'},
                    {label: '去完善', eventName: 'completed'}
                ]
            }
        },
        showDialog: {
            type: Boolean,
            default: true
        }
    },
    setup(props, context) {
         const state= reactive({
        })
        const showTitle = computed(() => {
            return props.title.length > 0
        })
        function handleEvent(eventName, value) {
            context.emit(eventName, value)
        }
        return {
            ...toRefs(state),
            showTitle,
            handleEvent,
        }
    },
}
</script>
<style lang='scss' scoped>
.wrap {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(#000, 0.4);
    z-index: 9;
    .dialog {
        background: #fff;
        border-radius: $box-radius-small;
        position: absolute;
        width: 75%;
        margin-left: 12.5%;
        top:50%;
        transform: translateY(-60%);
        padding-top: 20px;
        color: #000;
        position: relative;
        &_close {
            position: absolute;
            width: 15px;
            height: 15px;
            background: url('../../assets/imgs/cross-gray.png') center center no-repeat;
            background-size: contain;
            right: 8px;
            top: 8px;
        }

        h3 {
            font-size: $font-middle;
            text-align: center;
            padding: 0px 0 13px 0;
        }
        &_content {
            padding: 0 $margin-big*2 $margin-big $margin-big*2;
            font-size: $font-small;
            line-height: 1.5em;
            color: #282828;
        }
        &_buttons {
            padding: 0 20px;
            border-top: solid $line-gray 1px;
            display: flex;
            li {
                width: 50%;
                text-align: center;
                padding: 10px 0;
                &:last-child {
                    border-left: solid $line-gray 1px;
                    color: $blue-light;
                }
                color: $gray-light;
            }
        }
    }
}
</style>