<template>
 <div class="home">
    <router-view v-slot="{Component}">
      <keep-alive>
        <component :is="Component"  v-if="$route.meta.keepAlive"/>
      </keep-alive>
      <component :is="Component"  v-if="!$route.meta.keepAlive"/>
    </router-view>
 </div>
</template>

<script>
import { provide } from '@vue/runtime-core'
import { loginData, chooseNumber } from './store'
export default {
  name: 'App',
  setup() {
    provide('loginStatus', loginData()),
    provide('chooseNumberInfo', chooseNumber())
    return {
    }
  }
}
</script>

<style>
#app {
  font-family: Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* text-align: center; */
}
.home_content {
  padding-bottom: 40px;
}
</style>
